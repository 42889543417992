import React, { Component } from "react";
import {
  ModalStyled,
  ModalBody,
  Reactangle,
  InviteButton,
  EmployeeList,
  InviteCheckBox,
  CheckMarkContainer,
  Employee,
} from "./styles";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
  inviteUserForJoiningChallenge,
  UpdateUserLiveChallengeStatus,
} from "../../../redux/actions";
import PropTypes from "prop-types";
import Image from "../../Image";
import {
  ImageContainer,
  EmployeeName,
  EmployeeInterestContainer,
} from "../../PeopleListViewV2/styles";
import { ImageUrl } from "../../../utils/constants";
import { COMPANY_NAME } from "../../../utils/config";
import { isNull } from "lodash";
import Waiting from "../../Waiting";
class InviteAmigoPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amigosList: [],
      uninviteUids: [],
    };
  }
  inviteAmigo = (amigo, alreadyInvited) => {
    const { amigosList, uninviteUids } = this.state;
    if (alreadyInvited) {
      if (uninviteUids.includes(amigo)) {
        let index = uninviteUids.findIndex((item) => item === amigo);
        if (index > -1) {
          uninviteUids.splice(index, 1);
        }
        amigosList.push(amigo);
      } else {
        uninviteUids.push(amigo);
        let index = amigosList.findIndex((item) => item === amigo);
        if (index > -1) {
          amigosList.splice(index, 1);
        }
      }
    } else if (amigosList.includes(amigo)) {
      let index = amigosList.findIndex((item) => item === amigo);
      if (index > -1) {
        amigosList.splice(index, 1);
      }
    } else {
      amigosList.push(amigo);
    }
    this.setState({
      amigosList: amigosList,
      uninviteUids: uninviteUids,
    });
  };
  selectAllAmigos = (e) => {
    e.preventDefault();
    let invitedAmigos = [];
    let uninviteUid = [];
    const { inviteAmigosList } = this.props;
    if (this.state.amigosList.length === inviteAmigosList.length) {
      invitedAmigos = [];
      inviteAmigosList.map((amigo) => {
        amigo.is_invited && uninviteUid.push(amigo.friend_id);
      });
    } else {
      inviteAmigosList.map((amigo) => {
        invitedAmigos.push(amigo.friend_id);
      });
    }
    this.setState({
      amigosList: invitedAmigos,
      uninviteUids: uninviteUid,
    });
  };
  redirectChallengeOnHide = () => {
    const {
      onHide,
      history,
      challengeId,
      plusAmigoButton,
      UpdateUserLiveChallengeStatus,
    } = this.props;
    plusAmigoButton ? null : history.push(`/challenges/${challengeId}`);
    onHide();
    UpdateUserLiveChallengeStatus();
  };
  onInvite = () => {
    const { amigosList, uninviteUids } = this.state;
    const {
      challengeId,
      inviteUserForJoiningChallenge,
      userId,
      plusAmigoButton,
      history,
    } = this.props;
    let object = {
      user_ids: amigosList,
      uninvite_uids: uninviteUids,
      challenge_id: challengeId,
      is_joined: 2,
    };
    inviteUserForJoiningChallenge(object, userId);
    this.setState({ amigosList: [], uninviteUids: [] });
    plusAmigoButton ? null : history.push(`/challenges/${challengeId}`);
  };
  componentDidUpdate(prevProps) {
    const { inviteAmigosList } = this.props;
    if (
      prevProps.inviteAmigosList !== inviteAmigosList &&
      inviteAmigosList.length > 0 &&
      !isNull(inviteAmigosList[0])
    ) {
      const { amigosList } = this.state;
      inviteAmigosList.map((data) => {
        if (data.is_invited && !amigosList.includes(data.friend_id))
          amigosList.push(data.friend_id);
      });
      this.setState({ amigosList: amigosList });
    }
  }
  render() {
    const { showModal, inviteAmigosList, inviteLoading } = this.props;
    const { amigosList } = this.state;
    let showListStatus =
      inviteAmigosList &&
      inviteAmigosList.length > 0 &&
      !isNull(inviteAmigosList[0]);
    return (
      <ModalStyled
        show={showModal}
        backdropClassName="drop-back"
        onHide={() => this.redirectChallengeOnHide()}
      >
        <Modal.Header closeButton />
        <ModalBody>
          <Reactangle>
            <div>{`Select ${
              COMPANY_NAME === "Woliba" ? "Buddies" : "Amigos"
            } below to join your challenge.`}</div>
            {showListStatus && (
              <InviteButton>
                <div
                  className={"button"}
                  onClick={(e) => this.selectAllAmigos(e)}
                >{`${
                    amigosList.length === inviteAmigosList.length ? "DE" : ""
                  }SELECT ALL`}</div>
                <div className={"button"} onClick={() => this.onInvite()}>
                  INVITE
                </div>
              </InviteButton>
            )}
          </Reactangle>
          <EmployeeList
            padding={
              inviteAmigosList.length === 0 || isNull(inviteAmigosList[0])
                ? 1
                : 0
            }
          >
            {inviteLoading ? (
              <Waiting />
            ) : showListStatus ? (
              inviteAmigosList.map((employee, index) => (
                <Employee key={index}>
                  <div className={"profile"}>
                    <ImageContainer width={"45px"}>
                      <Image image={employee.profile_image} />
                    </ImageContainer>
                  </div>
                  <div className={"nameAndWellnessInterest"}>
                    <EmployeeName color="#282C37">
                      {employee.fullname}
                    </EmployeeName>
                    <EmployeeInterestContainer marginLeft={"0px"}>
                      {employee.interests &&
                        employee.interests.map(
                          (Wellness, index) =>
                            index < 5 && (
                              <div key={index}>
                                <img
                                  src={`${ImageUrl}/${Wellness.interest_icon}`}
                                />
                              </div>
                            )
                        )}
                    </EmployeeInterestContainer>
                  </div>
                  <div className={"checkbox"}>
                    <CheckMarkContainer
                      onClick={() =>
                        this.inviteAmigo(
                          employee.friend_id,
                          employee.is_invited
                        )
                      }
                    >
                      <InviteCheckBox
                        checked={amigosList.includes(employee.friend_id)}
                      />
                    </CheckMarkContainer>
                  </div>
                </Employee>
              ))
            ) : (
              `No ${COMPANY_NAME === "Woliba" ? "Buddies" : "Amigos"} found`
            )}
          </EmployeeList>
          <InviteButton
            background={1}
            disabled={
              inviteAmigosList.length === 0 ||
              isNull(inviteAmigosList[0]) ||
              amigosList.length === 0
            }
          >
            <div
              className={"button"}
              onClick={() =>
                showListStatus && amigosList.length && this.onInvite()
              }
            >{`INVITE ${
                COMPANY_NAME === "Woliba" ? "BUDDIES" : "AMIGOS"
              }`}</div>
          </InviteButton>
        </ModalBody>
      </ModalStyled>
    );
  }
}
InviteAmigoPopUp.propTypes = {
  userId: PropTypes.number,
  showModal: PropTypes.bool,
  inviteUserForJoiningChallenge: PropTypes.func,
  onHide: PropTypes.func,
  inviteAmigosList: PropTypes.array,
  challengeId: PropTypes.number,
  plusAmigoButton: PropTypes.number,
  history: PropTypes.object,
  inviteLoading: PropTypes.bool,
  UpdateUserLiveChallengeStatus: PropTypes.func,
};
const mapStateToProps = (state) => ({
  inviteAmigoStatus: state.challenges.inviteAmigoStatus,
  inviteAmigosList: state.challenges.inviteAmigosList,
  inviteLoading: state.challenges.inviteLoading,
});
const mapDispatchToProps = (dispatch) => ({
  inviteUserForJoiningChallenge: (data, userId) =>
    dispatch(inviteUserForJoiningChallenge(data, userId)),
  UpdateUserLiveChallengeStatus: () =>
    dispatch(UpdateUserLiveChallengeStatus()),
});
export default connect(mapStateToProps, mapDispatchToProps)(InviteAmigoPopUp);
